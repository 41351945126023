import React from 'react';
import IdentityModal, { useIdentityContext } from 'react-netlify-identity-widget';
import 'react-netlify-identity-widget/styles.css';

import { Button } from '../components';
import { navigate, Link } from '@reach/router';

export const isBrowser = () => typeof window !== 'undefined';

export const AuthStatusView = () => {
  const identity = useIdentityContext();
  const [dialog, setDialog] = React.useState(false);
  const name = (identity && identity.user && identity.user.user_metadata && identity.user.user_metadata.full_name) || 'NoName';
  const isLoggedIn = identity && identity.isLoggedIn;
  return (
    <div>
      <Button big={true} onClick={() => setDialog(true)}>
        <svg width="1800" height="1800" viewBox="0 0 20 20">
          <path d="M12.522,10.4l-3.559,3.562c-0.172,0.173-0.451,0.176-0.625,0c-0.173-0.173-0.173-0.451,0-0.624l3.248-3.25L8.161,6.662c-0.173-0.173-0.173-0.452,0-0.624c0.172-0.175,0.451-0.175,0.624,0l3.738,3.736C12.695,9.947,12.695,10.228,12.522,10.4 M18.406,10c0,4.644-3.764,8.406-8.406,8.406c-4.644,0-8.406-3.763-8.406-8.406S5.356,1.594,10,1.594C14.643,1.594,18.406,5.356,18.406,10M17.521,10c0-4.148-3.374-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.147,17.521,10" />
        </svg>
        {isLoggedIn ? `Hello ${name}, Log out here!` : 'Log In'}
        <IdentityModal
          showDialog={dialog}
          onCloseDialog={() => setDialog(false)}
          onLogin={user => {
            navigate('/app/dashboard');
            if (user) {
              console.log('hello ', user.user_metadata);
            }
          }}
          onSignup={user => {
            navigate('/app/dashboard');
            if (user) {
              console.log('welcome ', user.user_metadata);
            }
          }}
          onLogout={() => console.log('bye ', name)}
        />
      </Button>
      {isLoggedIn && (
        <Button big={true}>
          <svg width="1800" height="1800" viewBox="0 0 20 20">
            <path d="M17.701,3.919H2.299c-0.223,0-0.405,0.183-0.405,0.405v11.349c0,0.223,0.183,0.406,0.405,0.406h15.402c0.224,0,0.405-0.184,0.405-0.406V4.325C18.106,4.102,17.925,3.919,17.701,3.919 M17.296,15.268H2.704V7.162h14.592V15.268zM17.296,6.352H2.704V4.73h14.592V6.352z M5.947,5.541c0,0.223-0.183,0.405-0.405,0.405H3.515c-0.223,0-0.405-0.182-0.405-0.405c0-0.223,0.183-0.405,0.405-0.405h2.027C5.764,5.135,5.947,5.318,5.947,5.541" />
          </svg>

          <Link to={'/app/dashboard'}>App Dashboard</Link>
        </Button>
      )}
    </div>
  );
};
